const title = "Vie Sleep";
const description =
  "Vie is a luxury hybrid mattress design company based in the UK. Each of our products deliver exceptional quality, ergonomic design and contemporary style.";

const favicon = require("assets/images/favicon.png");

export const helmet = {
  title,
  titleTemplate: "Vie Sleep - %s",
  htmlAttributes: { lang: "en" },
  meta: [
    { name: "description", content: description },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, user-scalable=no"
    },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "msapplication-navbutton-color", content: "#00899c" },
    { name: "msapplication-TileColor", content: "#00899c" },
    { name: "theme-color", content: "#00899c" },

    { property: "og:title", content: title },
    { property: "og:image:width", content: "880px" },
    { property: "og:image:height", content: "440px" },
    { property: "og:image:alt", content: description }
  ],
  link: [{ rel: "icon", type: "image/x-icon", href: favicon }]
};
