import * as React from "react";
import Fade from "react-reveal/Fade";

import VieOutlineLogo from "assets/svg/vie-outline-logo.svg";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./Footer.scss";

export const Footer = () => (
  <div className={s.footer}>
    <Container>
      <div className={s.footer__block}>
        <Row>
          <Fade delay={200}>
            <div className={s.footer__col}>
              <span>© viesleep.com</span>
            </div>
            <div className={s.footer__col}>
              <span>
                Bring your bedroom to <strong>life</strong>.
              </span>
              <VieOutlineLogo />
            </div>
          </Fade>
        </Row>
      </div>
    </Container>
  </div>
);
