import * as React from "react";
import Popup from "reactjs-popup";

import { Link } from "components/link/Link";
import { BurgerIcon } from "./BurgerIcon";
import { Menu } from "./Menu";

import VieLogo from "assets/svg/vie-logo.svg";

import s from "./Header.scss";

interface IHeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: IHeaderProps) => (
  <header className={s.header}>
    <div className={s.header__container}>
      <div className={s.header__content}>
        <Link to="/" className={s.header__logo}>
          <VieLogo className={s.header__logoSvg} />
        </Link>

        <div className={s.header__navigation}>
          {children}

          <Popup
            modal
            lockScroll={false}
            closeOnDocumentClick={false}
            trigger={open => <BurgerIcon open={open} />}
          >
            {close => <Menu close={close} />}
          </Popup>
        </div>
      </div>
    </div>
  </header>
);
