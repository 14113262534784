import * as React from "react";
import Helmet from "react-helmet";

import { helmet } from "utils/helmet";
import { Header } from "components/header/Header";
import { Link as HeaderLink } from "components/header/Link";
import { Footer } from "components/footer/Footer";
import { Devtools } from "components/devtools/Devtools";

import s from "./AppLayout.scss";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 1600,
    speedAsDuration: true,
    easing: "easeInOutCubic",
    offset: 90
  });
}

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === "development";

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      <HeaderLink name="About Vie" to="/#about-vie" />

      <HeaderLink name="Vie Mattress" to="/#vie-mattress" />

      <HeaderLink name="Vie Life" to="/#vie-life" />

      <HeaderLink name="Vie Pillow" to="/#vie-pillow" />

      <HeaderLink name="Vie Gallery" to="/#vie-gallery" />
    </Header>

    {children}

    <Footer />

    {isDev && <Devtools />}
  </div>
);
