import React from "react";

interface IBurgerIconProps {
  open: React.ReactNode;
}

export const BurgerIcon = ({ open, ...props }: IBurgerIconProps) => (
  <div className={open ? "burger-menu open" : "burger-menu"} {...props}>
    <div className="bar1" key="b1" />
    <div className="bar2" key="b2" />
  </div>
);
